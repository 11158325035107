import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React, { useState, useRef, useEffect } from 'react';
import TextField from '@mui/material/TextField';

import {
  useAuth,
  useUser
} from "@clerk/clerk-react";

const environment = process.env.REACT_APP_ENVIRONMENT; //prod or dev

let MAIN_API_NAME = "text_to_gif_ai_1"
// let PROMPT_PARAM_NAME = "prompt"

let MAIN_API_URL = "https://funquiz.app/modular2/text-to-gif-ai-1"

function UserEmailAddress() {
  const { isLoaded, isSignedIn, user } = useUser();

  if (!isLoaded || !isSignedIn) {
    return "";{/*null;*/}
  }
  return user.primaryEmailAddress.emailAddress;
}

function FxInputForm3() {
    const [imageURL1, setImageURL1] = useState(null);
    const [imageURL2, setImageURL2] = useState(null);

    const [vidURL1, setVidURL1] = useState(null);


    const [selectedFile1, setSelectedFile1] = useState(null);
  
    const videoRef1 = useRef();
    const updateVideo1 = () => { // reload the video, important or the current song won't change
      if(videoRef1.current){
        videoRef1.current.pause();
        videoRef1.current.load();
      }
    }

    const handleFileChange1 = (event) => {
      if (event.target.files.length > 0)
      {
        setSelectedFile1(event.target.files[0]);
        setVidURL1(URL.createObjectURL(event.target.files[0]));
        updateVideo1();
      }
    };


    // const [selectedFile2, setSelectedFile2] = useState(null);
  
    // const handleFileChange2 = (event) => {
    //   setSelectedFile2(event.target.files[0]);
    //   setImageURL2(URL.createObjectURL(event.target.files[0]));
    // };

    
  
    //const authenticatedFetch = useFetch();
    const { getToken } = useAuth();
    const emailAddr = UserEmailAddress();

    const [textOutput, setTextOutput] = useState('');

    const inputProps = {
      inputProps: {
        style: { textAlign: 'center' },
      },
    };
  
    const handleTextOutputChange = event => {
        {/*console.log('Saving value');
    console.log(event.target.value);*/}
        setTextOutput(event.target.value);
    };


    const [textInput1, setTextInput1] = useState('');
    const handleTextInputChange1 = event => {
        {/*console.log('Saving value');
    console.log(event.target.value);*/}
        setTextInput1(event.target.value);
    };

    const [textInput2, setTextInput2] = useState('');
    const handleTextInputChange2 = event => {
        {/*console.log('Saving value');
    console.log(event.target.value);*/}
        setTextInput2(event.target.value);
    };
  
    const inputProps1 = {
      inputProps: {
        style: { textAlign: 'center' },
      },
    };
  
    function scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // You can use 'auto' instead of 'smooth' for instant scrolling
      });
    }
    
    function handleUpload() {
      // scroll to top
      scrollToTop()

      setTextOutput("Loading...");
      // Implement upload logic here, e.g., using Fetch API or a library like Axios
      const formData = new FormData();
      // formData.append('video1', selectedFile1);
      // formData.append('image2', selectedFile2);
      formData.append('email', emailAddr);
      formData.append('env', environment);
      formData.append('api_name',MAIN_API_NAME)
      formData.append('prompt1',textInput1)

      
      // formData.append('prompt',textInput1);
      
      // console.log(selectedFile);

      const fetchX = async () => {
        const myToken = await getToken();
        // Make an API request to upload the file
        fetch(MAIN_API_URL, {
          mode: 'cors',
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${myToken}`,
          },
          body: formData,
        })
        .then(response => response.json())
        .then(response => {
          // scroll to top
          scrollToTop()

          // Handle response from server
          console.log(response["message"]);
          setTextOutput(response["message"]);
        })
        .catch(error => {
          // scroll to top
          scrollToTop()

          // Handle error
          setTextOutput("Sorry, action failed");//error.message);
          console.error(error);
          //console.log("error")
        });
      };
      fetchX();
    }

    const myaspectstyle = {
      width: "100%",
      height: "100%"
    }; {/*aim is to preserve aspect ratio of image*/}

    let inputDisplayText = <h2>Input</h2>;

    let imgElement;
    let outputDisplayText;
    if (textOutput == "")
    {
      imgElement = <p></p>;
      outputDisplayText = <p></p>;
    }
    else if (isValidHttpUrl(textOutput))
    {
      imgElement = <img src={textOutput} alt="a url" style={myaspectstyle}></img>
      outputDisplayText = <h2>Output</h2>;
    }
    else {
      /* console.log("greet2 $textOutput"); */
      imgElement = <p>{textOutput}</p>;
      outputDisplayText = <h2>Output</h2>;
    }

    let vidElement;
    if (textOutput == "")
    {
      vidElement = <p></p>;
    }
    else if (isValidHttpUrl(textOutput))
    {
      vidElement = (
      <video controls style={myaspectstyle}> 
        <source src={textOutput} type="video/mp4" /> 
      </video>
      );
    }
    else {
      /* console.log("greet2 $textOutput"); */
      vidElement = <p>{textOutput}</p>;
    }


    let vidElement1;
    if (vidURL1 && vidURL1.length > 2)
    {
      vidElement1 = (
        <video controls ref={videoRef1}  style={myaspectstyle}> 
          <source src={vidURL1} type="video/mp4" /> 
        </video>
        );
    }
    else {
      vidElement1 = <p></p>
    } 

    let imgElement2;
    if (imageURL2 && imageURL2.length > 2)
    {
      imgElement2 = <img src={imageURL2} alt="a url 1"  style={myaspectstyle}></img>
    }
    else {
      imgElement2 = <p></p>
    } 

    let outputArea = (
    <div>
    <Box display="flex" 
         alignItems="center" 
         bgcolor="white"
         marginTop={0} 
         marginBottom={0}
         justifyContent="center">
      {outputDisplayText}
    </Box>
    <Box display="flex" 
         alignItems="center" 
         bgcolor="white"
         marginTop={0} 
         marginBottom={5}
         marginLeft={0} 
         marginRight={0} 
         justifyContent="center">
      {imgElement}
    </Box>
    <hr />
    </ div>);
  
    if (textOutput == "")
    {
      outputArea = <div></div>
    }

    return (
      <div>
        {outputArea}
        <Box display="flex" 
             alignItems="center" 
             bgcolor="white"
             marginTop={0} 
             marginBottom={0}
             justifyContent="center">
          {inputDisplayText}
        </Box>
        {/* <Box display="flex" 
             marginTop={0} 
             alignItems="center" 
             bgcolor="white"
             justifyContent="center">
        <p>Choose a source video</p>
        </Box> */}
        <Box display="flex" 
             marginTop={5} 
             alignItems="center" 
             bgcolor="white"
             justifyContent="center">
        <TextField 
          id="outlined-basic" 
          label="Enter some text..." 
          variant="outlined" 
          InputProps={inputProps}
          value={textInput1}
          multiline
          onChange={handleTextInputChange1}/>
        </Box>

        <Box display="flex" 
             marginTop={2} 
             marginLeft={5}
             marginRight={5}
             alignItems="center" 
             bgcolor="white"
             justifyContent="center">
        </Box>
        {/* <Box display="flex" 
             marginTop={5} 
             alignItems="center" 
             bgcolor="white"
             justifyContent="center">
        <TextField 
          id="outlined-basic" 
          label="Edit instructions..." 
          variant="outlined" 
          InputProps={inputProps}
          value={textInput1}
          multiline
          onChange={handleTextInputChange1}/>
        </Box> */}
        <Box display="flex" 
             marginTop={5} 
             marginBottom={5} 
             alignItems="center" 
             bgcolor="white"
             justifyContent="center">
        <Button variant="contained"
                onClick={handleUpload}>Submit Input</Button>
        </ Box>
        <Box display="flex" 
             marginTop={2} 
             marginBottom={8} 
             marginLeft={0} 
             marginRight={0} 
             alignItems="center" 
             bgcolor="white"
             justifyContent="center"
             objectFit="contain">
          {vidElement1}
        </Box>
        <Box display="flex" 
             marginTop={2} 
             marginBottom={8} 
             marginLeft={0} 
             marginRight={0} 
             alignItems="center" 
             bgcolor="white"
             justifyContent="center"
             objectFit="contain">
          {imgElement2}
        </Box>
      </div>
    );
  }

  function isValidHttpUrl(string) {
    let url;
    
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }
  
    return url.protocol === "http:" || url.protocol === "https:";
  }

  export default FxInputForm3;